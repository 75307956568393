import { defineComponent, onMounted } from 'vue';
export default defineComponent({
    name: 'Page',
    setup() {
        onMounted(() => {
            setTimeout(() => {
                const observer = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            const target = entry.target;
                            target.classList.add('is-visible');
                            observer.unobserve(target);
                        }
                    });
                });
                document
                    .querySelectorAll('.fade-in-element')
                    .forEach((element) => {
                    element.classList.remove('is-visible');
                    observer.observe(element);
                });
            }, 500);
        });
    },
});
